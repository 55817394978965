import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { themeGet } from 'styled-system';
import AnimationHorizontal from '../components/Animate';

import Layout from '../components/Layout';
import Block from '../components/Block';
import TextBlock from '../components/TextBlock';
import Sitewidth from '../components/Sitewidth';
import ImageCard from '../components/Cards/ImageCard';

const Content = styled(Block)`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
  margin-left: -5px;

  @media(max-width: ${themeGet('breakpoints.1')}) {
    margin: 0;
  }

  @media(min-width: ${themeGet('breakpoints.2')}) {
    justify-content: flex-start;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  
  @media(min-width: ${themeGet('breakpoints.1')}) {
    width: 50%;
  }

  @media(min-width: ${themeGet('breakpoints.2')}) {
    width: 33.33%;
  }
`;

const Portfolio = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query Portfolio {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/portfolio/"}}
        sort: { fields: frontmatter___date }
      ) {
        edges {
          node {
            frontmatter {
              slug
              title
              general {
                shortTitle
                tags
                category
                subcategory
                brandColor
              }
              header {
                cover {
                  type
                  image {
                    ...imageFragment
                  }
                  video
                }
                video
              }
              project {
                quote
                image {
                  ...imageFragment
                }
                description
                text {
                  content
                  type
                }
                gallery {
                  galleryImage {
                    ...imageFragment
                  }
                }
              }
              stats {
                numbers {
                  value
                  symbol
                  label
                }
                text {
                  content
                  type
                }
              }
              testimonial {
                text {
                  content
                  type
                }
              }
            }
          }
        }
      }
    }
  `);
  const data = allMarkdownRemark.edges;
  return (
    <Layout>
      <Sitewidth py={[4, 5, 6]}>
        <TextBlock
          title="Portfolio"
          as="h1"
          label=""
        />
        <Content>
          {data && data.map((item, index) => {
            const { frontmatter } = item.node;
            const isTitleTooLong = frontmatter.general.shortTitle.length === 0
              ? frontmatter.title
              : frontmatter.general.shortTitle;

            return (
              <Wrapper key={frontmatter.title}>
                <AnimationHorizontal direction="btt" delay={index * 20}>
                  <ImageCard
                    title={isTitleTooLong}
                    as="h4"
                    subcategory={frontmatter.general.subcategory}
                    src={frontmatter.header.cover.image.childImageSharp.fluid}
                    to={`/portfolio/${frontmatter.slug}`}
                  />
                </AnimationHorizontal>
              </Wrapper>
            );
          })}
        </Content>
      </Sitewidth>
    </Layout>
  );
};

export default Portfolio;
